import { get, post } from 'axios';
import Swal from "sweetalert2";

const protocol = 'https://'
const host = process.env.VUE_APP_CCAPI
const business = process.env.VUE_APP_BUSINESS_FONCABSA
const hostMultiApi = process.env.VUE_APP_MULTIAPI;

function getStatusVerificationMati(mati_verification_table_type, mati_verification_table_id) {
    const resource = `/${business}/check_identity_verification`
    const API_URL = `${host}${resource}`

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    const params = {
        business: "foncabsa",
        mati_verification_table_type: mati_verification_table_type,
        mati_verification_table_id: mati_verification_table_id
    }

    return get(API_URL, { params, config })
}

function getDataVerificationMati(mati_identity_id) {

    const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/get_verification_data`
    const API_URL = `${host}${resource}`

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    const params = {
        identity_id: mati_identity_id
    }

    return get(API_URL, { params, config })
}

function getDataVerificationByProcess(admission_id) {

    const resource = `/${business}/get_verification_by_process`
    const API_URL = `${host}${resource}`

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    const params = {
        business: "foncabsa",
        mati_verification_table_type: "admission_requests",
        mati_verification_table_id: admission_id
    }

    return get(API_URL, { params, config })
}

function registerDataVerificationMati(mati_identity_id) {

    const resource = `/${business}/register_identity_verification`
    const API_URL = `${host}${resource}`

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    const params = {
        identity_id: mati_identity_id
    }

    return post(API_URL, params, config)
}

function saveRequest(request) {

    const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/create`
    const API_URL = `${host}${resource}`

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };
    return post(API_URL, request, config)
}

function getAdmissionRequest(token, person_id) {
    const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/get_by_user`
    const API_URL = `${host}${resource}`

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    const params = {
        token_auth: token,
        person_id: person_id
    }

    return get(API_URL, { params, config })
}

function validityRFC(rfc) {
    const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/rfc_is_valid`
    const API_URL = `${host}${resource}`

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    const params = {
        rfc: rfc
    }

    return get(API_URL, { params, config })
}

function getAdmissionRequestOrCreate(token, person_id) {
    const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/get_admission_request_by_user_or_create`
    const API_URL = `${host}${resource}`

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    const params = {
        token_auth: token,
        person_id: person_id
    }

    return get(API_URL, { params, config })
}

function setBirthday(rfc) {
    const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/get_date_by_rfc`
    const API_URL = `${host}${resource}`

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    const params = {
        rfc: rfc
    }

    return get(API_URL, { params, config })
}

function validateMatchRFCwithName(rfc, birthday, name, middle_name, paternal_name, maternal_name) {
    const resource = `/${business}/rfc_validator`
    const API_URL = `${host}${resource}`

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    const params = {
        rfc: rfc,
        birthday: birthday,//"1986-03-04",
        name: name,
        middle_name: middle_name,
        paternal_name: paternal_name,
        maternal_name: maternal_name
    }
    // console.log("params: ",params);
    return get(API_URL, { params, config })
}

function validateMatchRfcWithNameWithoutDate(rfc, name, middle_name, paternal_name, maternal_name) {
    const resource = `/${business}/rfc_validator`
    const API_URL = `${host}${resource}`

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    const params = {
        rfc: rfc,
        name: name,
        middle_name: middle_name,
        paternal_name: paternal_name,
        maternal_name: maternal_name
    }
    console.log("params: ", params);
    return get(API_URL, { params, config })
}

function referenceIsAvailable(reference) {
    const resource = `/${business}/reference_is_available`
    const API_URL = `${host}${resource}`

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    const params = {
        reference: reference
    }

    return get(API_URL, { params, config })
}

function getCountries(token) {
    const resource = `/${business}/catalog_country/get_all?token_auth=${token}`;
    const API_URL = `${host}${resource}`;

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    const params = {
        token_auth: token
    };

    return get(API_URL, { params, config });
}

function getStates(token) {
    const resource = `/Api/Catalogo/Sepomex/obtener_estados?token_auth=${token}`;
    const API_URL = `${hostMultiApi}${resource}`;

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    const params = {
        withCredentials: true,
        token_auth: token
    };

    return post(API_URL, { params, config }, {
        auth: {
            username: "multicr",
            password: "*cabsa2018*"
        }
    });
}

function getMunicipalities(token, state) {
    const resource = `/Api/Catalogo/Sepomex/obtener_municipios_por_estado?token_auth=${token}`;
    const API_URL = `${hostMultiApi}${resource}`;

    const config = {
        contenido: {
            estado: state,
        },
        withCredentials: true,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
    };

    const params = {
        token_auth: token
    };

    return post(
        API_URL, config, {
        auth: {
            username: "multicr",
            password: "*cabsa2018*",
        },
    }
    )
}

function getCities(token, state, municipality) {
    const resource = `/Api/Catalogo/Sepomex/obtener_ciudades_por_estado_y_municipio?token_auth=${token}`;
    const API_URL = `${hostMultiApi}${resource}`;

    const config = {
        contenido: {
            estado: state,
            municipio: municipality
        },
        withCredentials: true,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
    };

    const params = {
        token_auth: token
    };

    return post(
        API_URL, config, {
        auth: {
            username: "multicr",
            password: "*cabsa2018*",
        },
    }
    )
}

async function getMissingData(token, clientId, process_name){
    const resource = `/${business}/clients/get_missing_data`;
    const API_URL = `${host}${resource}`;

    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    const params = {
        token_auth: token,
        client_id: `${clientId}`,
        process_name: process_name
    };

    return await get(API_URL, {params, config});
}

async function getMissingDataObject(){
    const resource = `/json/missingData.json`;
    const API_URL = `${resource}`;

    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    const params = {};

    return await get(API_URL, {params, config});
}

async function updateHousingData(token, clientId, residenceStatus, residenceStart){
    const resource = `/${business}/clinets/update_housing_data`;
    const API_URL = `${host}${resource}`;

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    const params = {
        "token_auth": token,
        "client_id": `${clientId}`,
        "residence_status": residenceStatus,
        "residence_start": residenceStart
    }

    return await post(API_URL, params, config);
}

async function updateSchoolData(token, clientId, educationLevel){
    const resource = `/${business}/clients/update_school_data`;
    const API_URL = `${host}${resource}`;

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    const params = {
        "token_auth": token,
        "client_id": `${clientId}`,
        "education_level": educationLevel
    }

    return await post(API_URL, params, config);
}

async function updateEconomicInformation(token, clientId, monthlyIncome, liquidity, incomeFrecuency){
    const resource = `/${business}/clients/update_economic_information`;
    const API_URL = `${host}${resource}`;

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    const params = {
        "token_auth": token,
        "client_id": `${clientId}`,
        "monthly_income": monthlyIncome,
        "liquidity": liquidity,
        "income_frecuency": incomeFrecuency
    }

    return await post(API_URL, params, config);
}

async function updateBeneficiaries(token, clientId, beneriariesList){
    const resource = `/${business}/clients/update_beneficiaries`;
    const API_URL = `${host}${resource}`;

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    const params = {
        "token_auth": token,
        "client_id": `${clientId}`,
        "beneficiaries": beneriariesList
    }

    return await post(API_URL, params, config);
}

async function createMissingDataArray(data){
    let missingDataList = Object.keys(data).filter(missingData => data[missingData] == false);
    let missinDataObject = await getStructureMissingData();

    let list = missingDataList.map(missingData => {
      let missingDataSelected = missinDataObject[missingData];
      return { module_name: (missingDataSelected != undefined)? missingDataSelected.module_name: '',
        module_content: missingDataSelected.module_content,
        module_key: missingDataSelected.module_key,
        message: (missingDataSelected != undefined)? missingDataSelected.missing_data_message: '',
        route: (missingDataSelected != undefined)? missingDataSelected.route: ''}
    });

    
    let newMissinDatalist = [];
    for (let i in Object.keys(list)) {
        if(typeof(list[i]) == "object")
        {
            newMissinDatalist.push(list[i]);
        }
    }

    return newMissinDatalist;
}

async function getStructureMissingData(){
    return await getMissingDataObject().then(resp =>  resp.data ).catch(error => null); 
}

function updateOnlyStatus(token_auth, admission_request_id, change_for) {

    const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/change_only_status`
    const API_URL = `${host}${resource}`

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    const params = {
        token_auth,
        admission_request_id,
        change_for
    }

    return post(API_URL, params, config)
}

export {
    getStatusVerificationMati,
    getDataVerificationMati,
    getDataVerificationByProcess,
    registerDataVerificationMati,
    saveRequest,
    getAdmissionRequest,
    validityRFC,
    setBirthday,
    validateMatchRFCwithName,
    getAdmissionRequestOrCreate,
    validateMatchRfcWithNameWithoutDate,
    referenceIsAvailable,
    getCountries,
    getStates,
    getMunicipalities,
    getCities,
    getMissingData,
    getMissingDataObject,
    updateHousingData,
    updateSchoolData,
    updateEconomicInformation,
    updateBeneficiaries,
    createMissingDataArray,
    getStructureMissingData,
    updateOnlyStatus,
}